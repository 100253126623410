.baner {
  background-color: var(--blue-color);

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include big-tablet {
      gap: 30px;
    }

    @include small-tablet {
      flex-direction: column;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 40px;
    color: var(--light-color);
  }

  &__text {
    color: var(--light-color);
  }
}
