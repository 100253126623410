.about {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 50px;

    @include high-tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;

      &--1,
      &--2,
      &--3,
      &--4 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
