.burger {
  display: none;
  @include tablet {
    display: block;
    visibility: visible;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    width: 44px;
    height: 44px;

    &__line {
      position: absolute;
      left: 50%;
      top: 50%;

      border-radius: 100px;
      width: 26px;

      height: 2px;
      background-color: var(--light-color);
      transform: translateX(-50%) translateY(-50%);

      &:first-child {
        margin-top: -7px;
      }

      &:last-child {
        left: 9px;
        width: 13px;
        margin-top: 7px;
        transform: translateY(-50%);
      }
    }
  }
}

.burger-close {
  @include tablet {
    position: absolute;
    top: 30px;
    right: var(--container-offset);
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    width: 44px;
    height: 44px;
    background-image: url("..//img/close-burger.svg");
    background-position: center;
    background-size: 26px 26px;
    background-repeat: no-repeat;
  }
}
