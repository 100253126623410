.hero {
  background-color: #f4f7fb5f;
  padding-top: 50px;
  padding-bottom: 50px;

  &__block {
    display: flex;
    gap: 50px;

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include high-tablet {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 45px;
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 80px;
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include big-tablet {
      flex-direction: column;
      gap: 15px;
    }
  }

  &__info {
    display: flex;
    gap: 80px;

    &--subtitle {
      font-weight: 600;
    }
  }
}
