// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Roboto", "Forum", sans-serif;
  --content-width: 1160px;
  --content-fluid-width: 1720px;

  --container-offset: 50px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(
    var(--content-flued-width) + (var(--container-offset) * 2)
  );

  // colors
  --light-color: #fff;
  --accent-color: #e31e24;
  --blue-color: #038fd2;
  --dark-color: #000;
}
