.foryou {
  background-color: #f4f7fb5f;

  &__title {
    font-size: 45px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;

    @include big-tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    padding: 25px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 4px 4px 27px 0px rgba(34, 60, 80, 0.2);
  }
}
