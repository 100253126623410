.nav {
  &__list {
    @include flex-v-center;

    @include tablet {
      @include flex-all-center;

      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
  &__item {
    &:not(:last-child) {
      margin-right: 40px;

      @include tablet {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
  &__link {
    font-family: Forum;
    font-size: 20px;
    font-weight: 400;
    line-height: 160%;
    color: var(--light-color);

    @include tablet {
      font-size: 30px;
    }
  }

  @include tablet {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: var(--dark-color);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    color: var(--light-color);
  }
}

.menu--active {
  opacity: 1;
  visibility: visible;
}
