.footer {
  background-color: var(--blue-color);

  &__container {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &--color {
      color: var(--light-color);
    }
  }

  &__doc {
    color: var(--light-color);

    &:hover {
      color: orange;
    }
  }

  &__social-list {
    margin-bottom: 15px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
